<template>
  <div class="todo-list">
    <div class="van-main">
      <van-tabs v-model="active">
        <van-tab title="待办">
          <div class="waitList">
            <van-list
              v-model="waitLoading"
              :finished="waitFinished"
              finished-text="没有更多了"
              @load="waitOnLoad"
            >
              <div v-for="item in waitList" :key="item.id" class="todo-item">
                <div v-if="viewDetail()">
                  <p><span>{{ item.process }}</span></p>
                  <p><span>{{ item.title }}</span></p>
                  <p><span>{{ item.createTime }}</span></p>
                  <van-button plain round size="small" type="info" native-type="button" @click="view('confirm',item)">
                    确认
                  </van-button>
                  <van-divider />
                </div>
                <div v-else>
                  <p><span>{{ item.procName }}</span></p>
                  <p><span>{{ item.motif }}</span></p>
                  <p><span>{{ item.sendTime }}</span></p>
                  <van-button plain round size="small" type="info" native-type="button" @click="detail(item, 0)">
                    审核
                  </van-button>
                  <van-divider />
                </div>
              </div>
            </van-list>
          </div>
        </van-tab>
        <van-tab title="已办">
          <div class="completeList">
            <van-list
              v-model="completeLoading"
              :finished="completeFinished"
              finished-text="没有更多了"
              @load="completeOnLoad"
            >
              <div v-for="item in completeList" :key="item.id" class="todo-item">
                <div v-if="viewDetail()">
                  <p><span>{{ item.process }}</span></p>
                  <p><span>{{ item.title }}</span></p>
                  <p><span>{{ item.createTime }}</span></p>
                  <van-button plain round size="small" type="info" native-type="button" @click="view('detail',item)">
                    查看详情
                  </van-button>
                  <van-divider />
                </div>
                <div v-else>
                  <p><span>{{ item.procName }}</span></p>
                  <p><span>{{ item.motif }}</span></p>
                  <p><span>{{ item.sendTime }}</span></p>
                  <van-button plain round size="small" type="info" native-type="button" @click="detail(item, 1)">
                    查看
                  </van-button>
                  <van-divider />
                </div>
              </div>
            </van-list>
          </div>
        </van-tab>
        <van-tab title="消息">
          <div class="message">
            <van-list
              v-model="messageLoading"
              :finished="messageFinished"
              finished-text="没有更多了"
              @load="getMessageList"
            >
              <div v-for="item in messageList" :key="item.id" class="todo-item">
                <p><span>{{ utils.isEffectiveCommon(item.title) }}</span></p>
                <p><span>{{ utils.isEffectiveCommon(item.content) }}</span></p>
                <p><span>{{ utils.isEffectiveCommon(item.createTime) }}</span></p>
                <van-button plain round size="small" type="info" native-type="button" @click="goDetail(item)">
                  查看
                </van-button>
                <van-divider />
              </div>
            </van-list>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <tab-bar active="message" />
  </div>
</template>

<script>
import TabBar from '@/components/tab-bar'
import { Dialog, Cell, CellGroup, Search, List, Divider, Button, Radio, RadioGroup, Toast, Tab, Tabs } from 'vant'
import Url from 'url-parse'
export default {
  components: {
    TabBar,
    [Dialog.Component.name]: Dialog.Component,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [List.name]: List,
    [Button.name]: Button,
    [Divider.name]: Divider,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
    [Toast.name]: Toast,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs
  },
  data () {
    return {
      active: 0,
      waitSearchForm: {
        pageNum: 1,
        pageSize: 10
      },
      waitLoading: false,
      waitFinished: false,
      waitTableList: {
        list: []
      },
      waitList: [],
      completeSearchForm: {
        pageNum: 1,
        pageSize: 10
      },
      completeLoading: false,
      completeFinished: false,
      completeTableList: {
        list: []
      },
      completeList: [],
      messageSearchForm: {
        pageNum: 1,
        pageSize: 10
      },
      messageTableList: {
        list: []
      },
      messageList: [],
      messageFinished: false,
      messageLoading: false,
      userType: this._.get(this.$store.state, 'employeeInfo.userType', '')
    }
  },
  created () {
  },
  methods: {
    // 判断是否是企业客户的代办
    viewDetail () {
      return [4, 5].includes(Number(this.userType))
    },
    // 企业客户代办跳转
    view (type, row) {
      const data = this._.cloneDeep(row)
      switch (data.pcButtonUrl) {
        case 'PAYMENT':
          this.$router.push({ path: '/payment/detail', query: { collectionId: data.businessId, detailId: data.id, taskId: data.taskId, type: type } })
          break
      }
    },
    getWaitTodoList () {
      this.api.base[[4, 5].includes(Number(this.userType)) ? 'listUpcoming' : 'waitTodoList'](this.waitSearchForm).then(result => {
        this.waitTableList = result.data.value
        if (this.waitSearchForm.pageNum === 1) {
          this.waitList = result.data.value.list
        } else {
          this.waitList = this.waitList.concat(result.data.value.list)
        }
        this.waitLoading = false
        if (this.waitSearchForm.pageNum < this.waitTableList.pages) {
          this.waitSearchForm.pageNum += 1
        } else {
          this.waitFinished = true
        }
      }).finally(() => {
      })
    },
    waitOnLoad () {
      this.getWaitTodoList()
    },
    getCompleteTodoList () {
      this.api.base[[4, 5].includes(Number(this.userType)) ? 'listDone' : 'completeTodoList'](this.completeSearchForm).then(result => {
        this.completeTableList = result.data.value
        if (this.completeSearchForm.pageNum === 1) {
          this.completeList = result.data.value.list
        } else {
          this.completeList = this.completeList.concat(result.data.value.list)
        }
        this.completeLoading = false
        if (this.completeSearchForm.pageNum < this.completeTableList.pages) {
          this.completeSearchForm.pageNum += 1
        } else {
          this.completeFinished = true
        }
      }).finally(() => {
      })
    },
    getMessageList () {
      this.api.base.myMessageList(this.messageSearchForm).then(result => {
        this.messageTableList = result.data.value
        if (this.messageSearchForm.pageNum === 1) {
          this.messageList = result.data.value.list
        } else {
          this.messageList = this.messageList.concat(result.data.value.list)
        }
        this.messageLoading = false
        if (this.messageSearchForm.pageNum < this.messageTableList.pages) {
          this.messageSearchForm.pageNum += 1
        } else {
          this.messageFinished = true
        }
      }).finally(() => {
      })
    },
    goDetail (val) {
      if (val.mobileViewUrl) {
        val.procKey = val.mobileViewUrl
        this.detail(val, 1)
      } else {
        this.$router.push({ name: 'messageDetail', query: { id: val.id } })
      }
    },
    completeOnLoad () {
      this.getCompleteTodoList()
    },
    detail (row, taskViewMode) {
      console.log(row.procKey, 2)
      let pathName = ''
      switch (row.procKey) {
        case 'project_apply':
          pathName = 'projectDetail'
          break
        case 'order_apply':
          pathName = 'orderDetail'
          break
        case 'contract_seal':
          pathName = 'contractDetail'
          break
        case 'enterprise_access':
          pathName = 'taskCompanyAccess'
          break
        case 'transport_apply':
          pathName = 'taskLogisticsTransport'
          break
        case 'credit_lmt_adjusting':
          pathName = 'taskQuotaChange'
          break
        case 'charge_apply':
          pathName = 'paymentCollectionDetail'
          break
        case 'payment_apply':
          pathName = 'paymentManagementDoDetail'
          break
        case 'contract_filed':
          pathName = 'contractAudit'
          break
        case 'warehousing_co_add':
          pathName = 'warehouseAudit'
          break
        case 'warehousing_co_alter':
          pathName = 'warehouseAuditChange'
          break
        case 'charge_confirm':
          pathName = 'paymentCollectionDoDetail'
          break
        case 'payment_confirm':
          pathName = 'paymentManagementDoDetail'
          break
        case 'stock_in_plan':
          pathName = 'receiptPlanApproval'
          break
        case 'discharging_cargo':
          pathName = 'releaseAudit'
          break
        case 'customs_broker_add':
          pathName = 'customsBrokerApproval'
          break
        case 'customs_broker_alter':
          pathName = 'customsBrokerApprovalChange'
          break
        default:
          pathName = ''
      }
      if (pathName) {
        const viewUrl = Url(row.viewUrl, true)
        // taskViewMode = 1 查看详情时才需要 procInstId
        this.$router.push({ name: pathName, query: { ...{ detailId: row.id, id: row.businessId, stockOutId: row.stockOutId, procInstId: Number(taskViewMode) === 1 ? row.procInstId : '', taskId: row.taskId }, ...this.utils.filterEmptyValue(viewUrl.query), ...{ taskViewMode: taskViewMode }, type: 'all' } })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.todo-list {
  .todo-item {
    font-size: 14px;
    margin-top: 20px;
    position: relative;
    p {
      width: 70%;
      display: inline-block;
      overflow: hidden;
      display:-webkit-box;
      -webkit-box-orient:vertical;
      -webkit-line-clamp:1;
      margin-left: 20px;
      >span {
        //display: inline-block;
        height: 16px;
        line-height: 16px;
        vertical-align: middle;
        margin-left: 15px;
      }
    }
    button {
      position: absolute;
      height: 30px;
      top: calc(50% - 15px);
      right: 15px;
    }
    .van-divider {
      margin-top: 5px;
    }

  }
}

</style>
