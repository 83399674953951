var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "todo-list" },
    [
      _c(
        "div",
        { staticClass: "van-main" },
        [
          _c(
            "van-tabs",
            {
              model: {
                value: _vm.active,
                callback: function($$v) {
                  _vm.active = $$v
                },
                expression: "active"
              }
            },
            [
              _c("van-tab", { attrs: { title: "待办" } }, [
                _c(
                  "div",
                  { staticClass: "waitList" },
                  [
                    _c(
                      "van-list",
                      {
                        attrs: {
                          finished: _vm.waitFinished,
                          "finished-text": "没有更多了"
                        },
                        on: { load: _vm.waitOnLoad },
                        model: {
                          value: _vm.waitLoading,
                          callback: function($$v) {
                            _vm.waitLoading = $$v
                          },
                          expression: "waitLoading"
                        }
                      },
                      _vm._l(_vm.waitList, function(item) {
                        return _c(
                          "div",
                          { key: item.id, staticClass: "todo-item" },
                          [
                            _vm.viewDetail()
                              ? _c(
                                  "div",
                                  [
                                    _c("p", [
                                      _c("span", [_vm._v(_vm._s(item.process))])
                                    ]),
                                    _c("p", [
                                      _c("span", [_vm._v(_vm._s(item.title))])
                                    ]),
                                    _c("p", [
                                      _c("span", [
                                        _vm._v(_vm._s(item.createTime))
                                      ])
                                    ]),
                                    _c(
                                      "van-button",
                                      {
                                        attrs: {
                                          plain: "",
                                          round: "",
                                          size: "small",
                                          type: "info",
                                          "native-type": "button"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.view("confirm", item)
                                          }
                                        }
                                      },
                                      [_vm._v(" 确认 ")]
                                    ),
                                    _c("van-divider")
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  [
                                    _c("p", [
                                      _c("span", [
                                        _vm._v(_vm._s(item.procName))
                                      ])
                                    ]),
                                    _c("p", [
                                      _c("span", [_vm._v(_vm._s(item.motif))])
                                    ]),
                                    _c("p", [
                                      _c("span", [
                                        _vm._v(_vm._s(item.sendTime))
                                      ])
                                    ]),
                                    _c(
                                      "van-button",
                                      {
                                        attrs: {
                                          plain: "",
                                          round: "",
                                          size: "small",
                                          type: "info",
                                          "native-type": "button"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.detail(item, 0)
                                          }
                                        }
                                      },
                                      [_vm._v(" 审核 ")]
                                    ),
                                    _c("van-divider")
                                  ],
                                  1
                                )
                          ]
                        )
                      }),
                      0
                    )
                  ],
                  1
                )
              ]),
              _c("van-tab", { attrs: { title: "已办" } }, [
                _c(
                  "div",
                  { staticClass: "completeList" },
                  [
                    _c(
                      "van-list",
                      {
                        attrs: {
                          finished: _vm.completeFinished,
                          "finished-text": "没有更多了"
                        },
                        on: { load: _vm.completeOnLoad },
                        model: {
                          value: _vm.completeLoading,
                          callback: function($$v) {
                            _vm.completeLoading = $$v
                          },
                          expression: "completeLoading"
                        }
                      },
                      _vm._l(_vm.completeList, function(item) {
                        return _c(
                          "div",
                          { key: item.id, staticClass: "todo-item" },
                          [
                            _vm.viewDetail()
                              ? _c(
                                  "div",
                                  [
                                    _c("p", [
                                      _c("span", [_vm._v(_vm._s(item.process))])
                                    ]),
                                    _c("p", [
                                      _c("span", [_vm._v(_vm._s(item.title))])
                                    ]),
                                    _c("p", [
                                      _c("span", [
                                        _vm._v(_vm._s(item.createTime))
                                      ])
                                    ]),
                                    _c(
                                      "van-button",
                                      {
                                        attrs: {
                                          plain: "",
                                          round: "",
                                          size: "small",
                                          type: "info",
                                          "native-type": "button"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.view("detail", item)
                                          }
                                        }
                                      },
                                      [_vm._v(" 查看详情 ")]
                                    ),
                                    _c("van-divider")
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  [
                                    _c("p", [
                                      _c("span", [
                                        _vm._v(_vm._s(item.procName))
                                      ])
                                    ]),
                                    _c("p", [
                                      _c("span", [_vm._v(_vm._s(item.motif))])
                                    ]),
                                    _c("p", [
                                      _c("span", [
                                        _vm._v(_vm._s(item.sendTime))
                                      ])
                                    ]),
                                    _c(
                                      "van-button",
                                      {
                                        attrs: {
                                          plain: "",
                                          round: "",
                                          size: "small",
                                          type: "info",
                                          "native-type": "button"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.detail(item, 1)
                                          }
                                        }
                                      },
                                      [_vm._v(" 查看 ")]
                                    ),
                                    _c("van-divider")
                                  ],
                                  1
                                )
                          ]
                        )
                      }),
                      0
                    )
                  ],
                  1
                )
              ]),
              _c("van-tab", { attrs: { title: "消息" } }, [
                _c(
                  "div",
                  { staticClass: "message" },
                  [
                    _c(
                      "van-list",
                      {
                        attrs: {
                          finished: _vm.messageFinished,
                          "finished-text": "没有更多了"
                        },
                        on: { load: _vm.getMessageList },
                        model: {
                          value: _vm.messageLoading,
                          callback: function($$v) {
                            _vm.messageLoading = $$v
                          },
                          expression: "messageLoading"
                        }
                      },
                      _vm._l(_vm.messageList, function(item) {
                        return _c(
                          "div",
                          { key: item.id, staticClass: "todo-item" },
                          [
                            _c("p", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.utils.isEffectiveCommon(item.title)
                                  )
                                )
                              ])
                            ]),
                            _c("p", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.utils.isEffectiveCommon(item.content)
                                  )
                                )
                              ])
                            ]),
                            _c("p", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.utils.isEffectiveCommon(item.createTime)
                                  )
                                )
                              ])
                            ]),
                            _c(
                              "van-button",
                              {
                                attrs: {
                                  plain: "",
                                  round: "",
                                  size: "small",
                                  type: "info",
                                  "native-type": "button"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.goDetail(item)
                                  }
                                }
                              },
                              [_vm._v(" 查看 ")]
                            ),
                            _c("van-divider")
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("tab-bar", { attrs: { active: "message" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }